  

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Zero Dot One AI React components
import MKBox from "components/MKBox";

// Zero Dot One AI React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
      <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="fastest-growing company"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
          Cultivating Success: Why Choose Us
          </MKTypography>
          <MKTypography variant="body1" color="text">
          selecting the right partner is paramount to 
            <br /> achieving your goals.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={70}
              suffix="%"
              title="Business Growth"
              description="Our platform empowers you with the tools and strategies needed to achieve substantial business growth."
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={80}
              suffix="%"
              title="Expand Network:"
              description="Connect with a wider network of industry professionals, clients, and partners to enhance your business opportunities."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={100}
              suffix="%"
              title="Go Global"
              description="Reach an international customers and expand your market presence beyond local boundaries."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
