// src/FloatingWhatsAppButton.js

import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const FloatingWhatsAppButtom = () => {
  const phoneNumber="7264052222"
const accountName="Zero.One AI"
const avatar="https://via.placeholder.com/150" // Example avatar
const chatMessage="Hello! How can we help you today?"
const statusMessage="Typically replies within 5 minutes"
const placeholder="Type a message..."
  return (
    <FloatingWhatsApp
      phoneNumber={phoneNumber}
      accountName={accountName}
      avatar={avatar}
      chatMessage={chatMessage}
      statusMessage={statusMessage}
      placeholder={placeholder}
    />
  );
};

export default FloatingWhatsAppButtom;
