// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Zero Dot One AI React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Zero.One AI",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "#",
    },
    {
      icon: <TwitterIcon />,
      link: "#",
    },
    {
      icon: <GitHubIcon />,
      link: "#",
    },
    {
      icon: <YouTubeIcon />,
      link: "#",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/pages/landing-pages/about-us" },
        //{ name: "auther", href: "/pages/landing-pages/author" },
        { name: "blog", href: "/pages/landing-pages/author" },
        { name: "contact us", href: "/pages/landing-pages/contact-us" },
      ],
    },
    {
      name: "products",
      items: [
        { name: "Automotive Resale Hub", href: "#" },
        { name: "Real Estate Sellers Hub", href: "#" },
        { name: "Finansial Brokers Hub", href: "#" },
        { name: "marchant Hub", href: "#" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "#" },
        { name: "privacy policy", href: "#" },
        
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Zero.One AI{" "}
    </MKTypography>
  ),
};
