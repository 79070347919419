  

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Zero Dot One AI React components
import MKBox from "components/MKBox";

// Zero Dot One AI React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="directions_car"
                    title="  Automotive Resale Hub"
                    description="A revolutionary car reselling platform that unifies car dealers into a single, comprehensive view, enhancing dealer visibility and customer access."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="home"
                    title="Real Estate Seller's Hub"
                    description="A robust real estate platform that consolidates property listings from multiple dealers, providing a seamless interface for buyers and sellers."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="account_balance"
                    title="Financial Brokers Hub"
                    description="An innovative platform that connects loan agents with potential clients, offering a cohesive view of financial solutions."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="store"
                    title="Merchant Hub"
                    description="A versatile hub for product sellers, providing tools and resources to connect with customers, manage inventory, and grow your business."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Discover Our Innovations"
              description="Explore how Zero Dot One AI leverages cutting-edge technology to drive business growth and efficiency across various industries."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
