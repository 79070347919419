  

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Zero Dot One AI React components
import MKBox from "components/MKBox";

// Zero Dot One AI React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                   Define Your Unique Value Proposition 
                  </>
                }
                description="Identify what sets you apart from the competition. Clearly articulate your unique strengths, values, and offerings that make you stand out."
              />
              <RotatingCardBack
                image={bgBack}
                title="Create a Strong Visual Identity"
                description="Develop a consistent visual identity, including a memorable logo, color scheme, and typography. Ensure that all marketing materials reflect this cohesive look."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start with header",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Build an Online Presence"
                  description="Establish a robust online presence through a professional website, active social media profiles, and relevant online directories."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Network and Collaborate"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Deliver Consistent Quality"
                  description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title=" Advertising and Promotions"
                  description=" Use targeted advertising and promotional campaigns to reach a broader audience. Consider using platforms like Google Ads, Facebook Ads, and other digital marketing tools to increase your brand's reach."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
