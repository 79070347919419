  
import carImage from "assets/images/carimg.jpg";
import propImage from "assets/images/bg-about-us.jpg";
import loanImage from "assets/images/loan.jpg";
import genericImage from "assets/images/generice.jpg";

const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/pages";

export default [
  {
    image: carImage,
    name: "  Automotive Resale Hub",
   // route: "/pages/landing-pages/about-us",
  },
  {
    image: propImage,
    name: "Real Estate Seller's Hub",
   // route: "/pages/landing-pages/about-us",
  },
  {
    image:loanImage,
    name: "Financial Brokers Hub",
   // route: "/pages/authentication/sign-in",
  },
  {
    image:genericImage,
    name: "Merchant Hub",
   // route: "/pages/landing-pages/author",
  },
];
